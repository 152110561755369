import PropTypes from 'prop-types'
import css from '@/styles/components/loading/LoadingCircle.scss'

const propTypes = {
    variant: PropTypes.oneOf(['blue']),
    size: PropTypes.number,
}

const LoadingCircle = ({ variant, size, className, color, ...props }) => {
    const propsMapCSS = {
        inlineBlock: css['inline-block'],
    }
    const style = {
        width: size || '100px',
        height: size || '100px',
        borderColor: color ? `${color} ${color} ${color} transparent` : null,
    }
    return (
        <div
            className={`${css.loading} ${css['size--' + size]} ${Object.keys(
                props
            )
                .filter((key) => key !== 'children')
                .filter((key) => key in propsMapCSS)
                .map((key) => ` ${propsMapCSS[key]}`)} ${className || ''}`}
            style={style}
        ></div>
    )
}

LoadingCircle.propTypes = propTypes

export default LoadingCircle
