const metaSets = {
    defaultLayout: [
        {
            tag: 'meta',
            attributes: {
                name: 'robot',
                content: 'noindex, nofollow'
            }
        },
        {
            tag: 'link',
            attributes: {
                rel: 'stylesheet',
                href: '//cdn.quilljs.com/1.2.6/quill.snow.css'
            }
        },
        {
            tag: 'script',
            attributes: {
                src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD60h2G05YmSjhx_qt0eyjLyHuHxeQY458&libraries=places&language=th'
            }
        },
        {
            tag: 'script',
            attributes: {
                async: true,
                src: 'https://www.instagram.com/embed.js'
            }
        },
        {
            tag: 'script',
            attributes: {
                async: true,
                src: 'https://platform.twitter.com/widgets.js'
            }
        },
        {
            tag: 'link',
            attributes: {
                rel: 'stylesheet',
                href: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css'
            }
        },
        {
            tag: 'link',
            attributes: {
                rel: 'stylesheet',
                href: 'https://cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css'
            }
        }
    ],
    blankLayout: []
}

export default metaSets
