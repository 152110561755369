import NextHead from 'next/head'

export const Meta = metaList => {
    if (
        !Array.isArray(metaList) ||
        typeof metaList === undefined ||
        metaList == null
    )
        return <></>

    return metaList.map((meta, index) => {
        if (meta.tag === undefined) return
        const TagName = meta.tag
        return <TagName key={`meta-${index}`} {...meta.attributes} />
    })
}

export const Head = ({ meta, children }) => {
    return (
        <NextHead>
            <link rel="shortcut icon" href="/favicon.ico" />
            <title>Estopolis Console Website</title>
            {meta && Meta(meta)}
            {children}
        </NextHead>
    )
}
