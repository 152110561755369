import PropTypes from 'prop-types'
import Icon from '../Common/Icon'
import LoadingCircle from '../Loading/LoadingCircle'
import { forwardRef } from 'react'
import Link from 'next/link'

const color = {
    PRIMARY: 'primary',
    SUCCESS: 'success',
    WARNING: 'warning',
    DANGER: 'danger',
    BRAND: 'brand',
    LIGHT: 'light',
    LIGHT2: 'light2'
}

const buttonStyle = {
    LIGHT: 'light'
}

const defaultProps = {
    isLoading: false
}

const propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'success',
        'warning',
        'danger',
        'brand',
        'light',
        'light2'
    ]),
    buttonStyle: PropTypes.oneOf(['light', null]),
    children: PropTypes.node,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    icon: PropTypes.string,
    iconStyle: PropTypes.oneOf([...Icon.styleList]),
    isLoading: PropTypes.bool,
    href: PropTypes.string,
    as: PropTypes.string
}

const Button = forwardRef(
    (
        {
            color,
            buttonStyle,
            children,
            type,
            isLoading,
            icon,
            iconStyle,
            renderAsLink,
            href,
            as,
            ...props
        },
        ref
    ) => {
        const applyButtonColor = style =>
            !color ? '' : style ? `btn-${style}-${color}` : `btn-${color}`
        const applyButtonStyle = `${buttonStyle &&
            `btn-${buttonStyle}`} ${applyButtonColor(buttonStyle)}`

        return (
            <LinkWrapper href={href} as={as}>
                <BlockWrapper
                    type={type || 'button'}
                    className={`btn
                        ${applyButtonStyle}
                        ${
                            type === 'submit' && !buttonStyle && !color
                                ? 'btn-primary'
                                : ''
                        }`}
                    ref={ref}
                    renderAsLink={renderAsLink}
                    {...props}
                >
                    {!isLoading && icon && (
                        <span className={children && 'btn-icon'}>
                            <Icon name={icon} iconStyle={iconStyle} />
                        </span>
                    )}
                    {isLoading && (
                        <LoadingCircle
                            className="btn-icon"
                            size={16}
                            inlineBlock
                        />
                    )}
                    {!isLoading && children}
                </BlockWrapper>
            </LinkWrapper>
        )
    }
)

const BlockWrapper = forwardRef(({ renderAsLink, children, ...props }, ref) => {
    return (
        <>
            {renderAsLink && (
                <a {...props} ref={ref}>
                    {children}
                </a>
            )}
            {!renderAsLink && (
                <button {...props} ref={ref}>
                    {children}
                </button>
            )}
        </>
    )
})

const LinkWrapper = ({ href, as, children }) => {
    return (
        <>
            {href && (
                <Link href={href} as={as} passHref>
                    {children}
                </Link>
            )}
            {!href && children}
        </>
    )
}

Button.color = color
Button.style = buttonStyle
Button.propTypes = propTypes
Button.defaultProps = defaultProps

export default Button
