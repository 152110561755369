import PropTypes from 'prop-types'

const propTypes = {
    name: PropTypes.string.isRequired,
    iconStyle: PropTypes.oneOf(['solid', 'regular', undefined]),
}

const styleNames = {
    solid: 'fas',
    regular: 'far',
}

const Icon = ({ name, iconStyle, className, ...props }) => {
    const styleName = styleNames[iconStyle] || 'fas'

    return (
        <i
            className={`${styleName} fa-${name.toLowerCase()} ${className}`}
            {...props}
        ></i>
    )
}
Icon.styleNames = styleNames
Icon.styleList = Object.keys(styleNames)
Icon.propTypes = propTypes

export default Icon
